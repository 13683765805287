'use client';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = () => {
  const router = useRouter();
  const pathname = usePathname();
  const params = useSearchParams();

  useEffect(() => {
    if (params && params.get('error')) {
      console.log('Error: ', params.get('error'));
      toast.error('An error occurred. Please try again later.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      const newParams = new URLSearchParams(params.toString());
      newParams.delete('error');
      const newParamsString =
        newParams.toString() === '' ? '' : '?' + newParams.toString();
      router.replace(pathname + newParamsString);
    }
  }, [params]);

  return <ToastifyContainer />;
};

export default ToastContainer;
