'use client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { usePathname } from 'next/navigation';

const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: ReactNode;
};
export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const pathname = usePathname();
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey]
  );

  useEffect(() => {
    window.analytics = analytics;
  }, [analytics]);

  useEffect(() => {
    analytics.page();
  }, [pathname, analytics]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

export enum EventNames {
  Signup = 'Signup',
  Login = 'Login',
  Logout = 'Logout',
  AuthError = 'Auth Error',
  DocumentRenamed = 'Document Renamed',
  DocumentDeleted = 'Document Deleted',
  DocumentShared = 'Document Shared',
  DocumentMoved = 'Document Moved',
  DocumentOpened = 'Document Opened',
  FolderOpened = 'Folder Opened',
  DocumentCreated = 'Document Created',
  UpgradeAccountClicked = 'Upgrade Account Clicked',
  PremiumAdDismissed = 'Premium Ad Dismissed',
  SubscribeButtonClicked = 'Subscribe Button Clicked',
  SubscribeBackButtonClicked = 'Subscribe Back Button Clicked',
  ManageSubscriptionClicked = 'Manage Subscription Clicked',
  SubscriptionStarted = 'Subscription Started',
  SubscriptionCancelled = 'Subscription Cancelled',
  PlayAudio = 'Play Audio',
  PauseAudio = 'Pause Audio',
  RepeatAudio = 'Repeat Audio',
  SkipToTrack = 'Skip To Track',
  PreviousTrack = 'Previous Track',
  NextTrack = 'Next Track',
  AudioError = 'Audio Error',
  OpenSpeedSelector = 'Open Speed Selector',
  SetSpeed = 'Set Speed',
  OpenVoiceSelector = 'Open Voice Selector',
  SetVoice = 'Set Voice',
  SetVolume = 'Set Volume',
  ToggleToolbar = 'Toggle Toolbar',
  LogoHomeButtonClicked = 'Logo Home Button Clicked',
  TabClicked = 'Tab Clicked',
  SummaryRegenClicked = 'Summary Regen Clicked',
  CloseChat = 'Close Chat',
  OpenChat = 'Open Chat',
  ChatSent = 'Chat Sent',
  ChatReceived = 'Chat Received',
  DeleteAllChats = 'Delete All Chats',
  ChatListenStarted = 'Chat Listen Started',
  ChatListenStopped = 'Chat Listen Stopped',
  ChatListenFinished = 'Chat Listen Finished',
  ChatError = 'Chat Error',
  SourcesToggled = 'Sources Toggled',
  SourceClicked = 'Source Clicked',
}
