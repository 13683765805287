'use client';

import { useSession } from 'next-auth/react';
import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import AuthService from '@/Services/AuthService';

export function RefreshProvider({ children }: { children: ReactNode }) {
  // TEMPORARY MITIGATION
  const router = useRouter();
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.error === 'RefreshTokensError') {
      // signIn(); // Force sign in to hopefully resolve error
      AuthService.logout();
      router.push('/login');
    }
  }, [session]);

  return <>{children}</>;
}
